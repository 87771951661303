<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-simple-table
      class="purchased-items-table"
      dense
      style="background-color: #fff;"
    >
      <template v-slot:default>
        <tbody>
          <fragment
            v-for="(item, indItem) in hotel.proveedores"
            :key="indItem"
          >
            <tr class="bgGray">
              <td colspan="11">
                <span class="d-flex flex-row">
                  <v-checkbox
                    v-model="item.check"
                    hide-details
                    class="my-0"
                  ></v-checkbox>
                  <span class="mt-2">
                    {{ `Proveedor #${indItem + 1}: ${item.proveedor.name}` }}
                  </span>
                </span>
              </td>
              <td
                class="text-right"
                width="10px"
              >
                <v-btn
                  icon
                  @click="showItem(indItem)"
                >
                  <v-icon>
                    {{ showRow && indItem === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>

            <fragment v-if="showRow && indItem === indexRow">
              <tr class="bgGray">
                <td class="text-uppercase">
                  {{ $t('lbl.room') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.oferta') }}
                </td>
                <td class="text-uppercase">
                  {{ `TW ${$t('lbl.from')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `TW ${$t('lbl.to')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `BW ${$t('lbl.from')}` }}
                </td>
                <td class="text-uppercase">
                  {{ `BW ${$t('lbl.to')}` }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.plan') }}
                </td>
                <td class="text-uppercase">
                  {{ $t('lbl.nights') }}
                </td>
                <td class="text-uppercase">
                  SGL
                </td>
                <td class="text-uppercase">
                  DBL
                </td>
                <td class="text-uppercase">
                  TPL
                </td>
                <td class="text-uppercase">
                  MNR
                </td>
              </tr>
              <fragment
                v-for="(room, indRoom) in item.rooms"
                :key="indRoom"
              >
                <tr
                  v-for="(typeOff, indOff) in room.room_data.type_offert"
                  :key="indOff"
                >
                  <td
                    v-if="indOff === 0"
                    :rowspan="room.room_data.type_offert.length"
                  >
                    {{ room.room_data.name }}
                  </td>
                  <td>{{ typeOff.type }}</td>
                  <td>{{ typeOff.twFrom }}</td>
                  <td>{{ typeOff.twTo }}</td>
                  <td>{{ typeOff.bwFrom }}</td>
                  <td>{{ typeOff.bwTo }}</td>
                  <td>{{ typeOff.plan }}</td>
                  <td style="text-align: center;">
                    {{ typeOff.nights }}
                  </td>
                  <td>{{ typeOff.sgl | currency('') }}</td>
                  <td>{{ typeOff.dbl | currency('') }}</td>
                  <td>{{ typeOff.tpl | currency('') }}</td>
                  <td>{{ typeOff.mnr | currency('') }}</td>
                </tr>
              </fragment>

              <fragment v-if="item.suplements.length > 0">
                <tr class="bgGray">
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('menu.suplementos') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.tipo') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.from') }}
                  </td>
                  <td class="text-uppercase">
                    {{ $t('lbl.to') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="2"
                  >
                    {{ $t('lbl.plan') }}
                  </td>
                  <td
                    class="text-uppercase"
                    colspan="3"
                  >
                    ADL
                  </td>
                  <td class="text-uppercase">
                    MNR
                  </td>
                </tr>
                <tr
                  v-for="(suplement, indSup) in item.suplements"
                  :key="indSup"
                >
                  <td colspan="2">
                    {{ suplement.name }}
                  </td>
                  <td colspan="2">
                    {{ suplement.required ? $t('lbl.obligatorio') : $t('lbl.optional') }}
                  </td>
                  <td></td>
                  <td></td>
                  <td colspan="2"></td>
                  <td colspan="3"></td>
                  <td></td>
                </tr>
              </fragment>
            </fragment>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
      },
      isDialogVisible: false,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      itemsDestinos: [],
      searchDestinos: null,
      noResultDestinos: false,
      itemsOperador: [],
      searchOperador: null,

      minDate: new Date(),
      menuFrom: false,
      menuTo: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemsList: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios']),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss">
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.bgGray {
  background-color: #b2b2b2;
}

tbody {
  tr:hover {
    background-color: #b2b2b2 !important;
  }
}
</style>
