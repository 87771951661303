var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-card-text',[(!_vm.$store.state.app.onlyShow)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.afiliates,"label":_vm.$t('lbl.afiliateCorporate'),"outlined":"","dense":"","hide-details":"","item-text":"name_comercial","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.afiliateCorporate'))+" ")])],1)]},proxy:true}],null,false,110853305),model:{value:(_vm.model.afiliado_id),callback:function ($$v) {_vm.$set(_vm.model, "afiliado_id", $$v)},expression:"model.afiliado_id"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.itemsTarifariosCheck.length === 0},on:{"click":_vm.send}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiSendOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","loading":_vm.loading,"disabled":_vm.itemsTarifariosCheck.length === 0},on:{"click":function($event){return _vm.actionMenu('download')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" "+_vm._s(_vm.$t('btn.dowload'))+" ")],1)],1)],1)],1)],1):_vm._e()],1),(_vm.model.afiliado_id)?_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(_vm.productsPermit.includes('vuelos'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAirplane))])],1)]}}],null,false,2281516607)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.flights')))])])],1):_vm._e(),(_vm.productsPermit.includes('cars'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCar))])],1)]}}],null,false,2254517539)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.cars')))])])],1):_vm._e(),(_vm.productsPermit.includes('hotels'))?_c('v-tab',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiOfficeBuilding))])],1)]}}],null,false,3337152709)},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.hotels')))])])],1):_vm._e()],1):_vm._e(),_c('v-divider'),(_vm.model.afiliado_id)?_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[(_vm.productsPermit.includes('vuelos'))?_c('v-tab-item'):_vm._e(),(_vm.productsPermit.includes('cars'))?_c('v-tab-item'):_vm._e(),(_vm.productsPermit.includes('hotels'))?_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('HotelsTab',{attrs:{"user":_vm.user,"model":_vm.model,"proveedores":_vm.proveedoresHotel,"operadores":_vm.operadoresHotel,"hotels":_vm.hotels},on:{"clearSearch":_vm.clearSearch}})],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.isSidebarSendActive)?_c('SidebarSend',{attrs:{"name-client":_vm.model.afiliado_id ? _vm.afiliates.filter(function (e) { return e.id === _vm.model.afiliado_id; })[0].name_comercial : '',"user":_vm.user},model:{value:(_vm.isSidebarSendActive),callback:function ($$v) {_vm.isSidebarSendActive=$$v},expression:"isSidebarSendActive"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }