var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-form',{staticClass:"multi-col-validation my-5 mx-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.proveedores,"label":_vm.$t('lbl.proveedor'),"outlined":"","dense":"","hide-details":"","item-text":"name_comercial","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")])],1)]},proxy:true}],null,false,3335563875),model:{value:(_vm.model.proveedor_id),callback:function ($$v) {_vm.$set(_vm.model, "proveedor_id", $$v)},expression:"model.proveedor_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"pr-2",attrs:{"items":_vm.itemsDestinos,"search-input":_vm.searchDestinos,"label":_vm.$t('lbl.destino'),"outlined":"","dense":"","item-text":"name","item-value":"name","single-line":""},on:{"update:searchInput":function($event){_vm.searchDestinos=$event},"update:search-input":function($event){_vm.searchDestinos=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultDestinos ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.destino'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1354869364),model:{value:(_vm.model.destino),callback:function ($$v) {_vm.$set(_vm.model, "destino", $$v)},expression:"model.destino"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.dateIn'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2330281198),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minDate.toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
              _vm.activeToDate()}},model:{value:(_vm.model.dateIn),callback:function ($$v) {_vm.$set(_vm.model, "dateIn", $$v)},expression:"model.dateIn"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.model.dateIn,"label":_vm.$t('lbl.dateOut'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1085812575),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.model.dateIn
                ? _vm.$moment(_vm.model.dateIn)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : ''},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.model.dateOut),callback:function ($$v) {_vm.$set(_vm.model, "dateOut", $$v)},expression:"model.dateOut"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.operadores,"label":_vm.$t('menu.operador'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('menu.operador'))+" ")])],1)]},proxy:true}],null,false,892770304),model:{value:(_vm.model.operador_id),callback:function ($$v) {_vm.$set(_vm.model, "operador_id", $$v)},expression:"model.operador_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-select',{attrs:{"items":_vm.hotelsFree,"label":_vm.$t('menu.hotels'),"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","multiple":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('menu.hotels'))+" ")])],1)]},proxy:true}],null,false,1736140537),model:{value:(_vm.model.hotels),callback:function ($$v) {_vm.$set(_vm.model, "hotels", $$v)},expression:"model.hotels"}})],1)],1)],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[(
          (_vm.model.proveedor_id && _vm.model.proveedor_id !== 0) ||
            (_vm.model.destino && _vm.model.destino !== 'Todos') ||
            _vm.model.dateIn ||
            _vm.model.dateOut ||
            (_vm.model.operador_id && _vm.model.operador_id !== 0) ||
            _vm.model.hotels.length > 0
        )?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.$emit('clearSearch')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEraserVariant)+" ")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"9"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.searchItems}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")]),_vm._v(" "+_vm._s(_vm.$t('btn.search'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.itemsTarifarios.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.itemsTarifarios.length)+" "+_vm._s(_vm.$t('lbl.de'))+" "+_vm._s(_vm.hotels.length)+" "+_vm._s(_vm.$t('menu.hotels').toLowerCase())+" ")]):_vm._e()])],1),_c('v-divider',{staticClass:"mb-4 mt-4"}),_c('HotelsList',{attrs:{"user":_vm.user,"model":_vm.model,"proveedores":_vm.proveedores,"operadores":_vm.operadores,"hotels":_vm.hotels}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }