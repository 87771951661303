<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row v-if="!$store.state.app.onlyShow">
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="model.afiliado_id"
                :items="afiliates"
                :label="$t('lbl.afiliateCorporate')"
                outlined
                dense
                hide-details
                item-text="name_comercial"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.afiliateCorporate') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              md="2"
              class="text-center"
            >
              <v-btn
                color="primary"
                outlined
                :disabled="itemsTarifariosCheck.length === 0"
                @click="send"
              >
                <v-icon class="mr-2">
                  {{ icons.mdiSendOutline }}
                </v-icon>
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="text-center"
            >
              <v-btn
                color="primary"
                outlined
                :loading="loading"
                :disabled="itemsTarifariosCheck.length === 0"
                @click="actionMenu('download')"
              >
                <v-icon class="mr-2">
                  mdi-file-pdf-box
                </v-icon>
                {{ $t('btn.dowload') }}
              </v-btn>
              <!--<v-row no-gutters>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-overflow-btn
                    :items="btnPrint"
                    :label="$t('btn.print')"
                    color="primary"
                    outlined
                    dense
                    :prepend-inner-icon="icons.mdiPrinter"
                    :loading="loading"
                    hide-details
                    :disabled="itemsTarifarios.length === 0"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon left>
                        {{ item.icon }}
                      </v-icon>
                      <v-list-item-content @click="actionMenu(item.slug)">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-overflow-btn>
                </v-col>
              </v-row>-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs
      v-if="model.afiliado_id"
      v-model="currentTab"
    >
      <v-tab v-if="productsPermit.includes('vuelos')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiAirplane }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.flights') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('cars')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiCar }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.cars') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('hotels')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.hotels') }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items
      v-if="model.afiliado_id"
      v-model="currentTab"
    >
      <v-tab-item v-if="productsPermit.includes('vuelos')">
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('cars')">
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('hotels')">
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <HotelsTab
              :user="user"
              :model="model"
              :proveedores="proveedoresHotel"
              :operadores="operadoresHotel"
              :hotels="hotels"
              @clearSearch="clearSearch"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <SidebarSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      :name-client="model.afiliado_id ? afiliates.filter(e => e.id === model.afiliado_id)[0].name_comercial : ''"
      :user="user"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiAirplane,
  mdiCar,
  mdiOfficeBuilding,
  mdiPrinter,
  mdiSendOutline,
} from '@mdi/js'

import SidebarSend from '../utils/SidebarSend.vue'

import FlightsTab from '../tabs/Flights.vue'
import CarsTab from '../tabs/Cars.vue'
import HotelsTab from '../tabs/Hotels.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FlightsTab,
    // eslint-disable-next-line vue/no-unused-components
    CarsTab,
    // eslint-disable-next-line vue/no-unused-components
    HotelsTab,
    SidebarSend,
  },
  data() {
    return {
      isSidebarSendActive: false,
      nameb2b: null,
      name_project: process.env.VUE_APP_PROJ_NAME,
      btnPrint: [
        { text: 'PDF', icon: 'mdi-file-pdf-box', slug: 'pdf' },
        { text: 'XLS', icon: 'mdi-file-excel', slug: 'xls' },
        { text: this.$t('btn.print'), icon: 'mdi-printer', slug: 'print' },
      ],
      isLoading: true,
      loading: false,
      currentTab: 2,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiAirplane,
        mdiCar,
        mdiOfficeBuilding,
        mdiPrinter,
        mdiSendOutline,
      },
      itemsPerPage: 10,
      permisos: sessionStorage.getItem('permisos_auth'),
      model: {
        afiliado_id: 0,
        proveedor_id: 0,
        destino: 'Todos',
        dateIn: null,
        dateOut: null,
        operador_id: 0,
        hotels: [],
      },
      afiliates: [],
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
      showTabs: true,
      items: [],
      user: {},
      productsPermit: [],
      proveedoresHotel: [],
      operadoresHotel: [],
      hotels: [],
    }
  },
  computed: {
    ...mapState({
      itemsTarifarios: state => state.app.itemsTarifarios,
    }),
    itemsTarifariosCheck() {
      const arr = []
      this.itemsTarifarios.forEach(element => {
        if (element.check) {
          arr.push(element)
        }
      })

      return arr
    },
  },
  mounted() {
    this.profile()
  },
  created() {
    this.getAfiliados()

    // HOTELS
    this.getProveedoresHotel()
    this.getOperadoresHotel()
    this.getHoteles()
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList', 'updateItemsTarifarios']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.getProductsPermit()
        })
    },
    actionMenu(slug) {
      this.loading = true

      const json = {
        action: slug,
        items: this.itemsTarifariosCheck,
      }
      if (slug === 'download') {
        this.axios
          .post('tarifarios/hotels/action', json, {
            responseType: 'arraybuffer',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res)
            const filename = 'Tarifario'

            this.downloadFile(res, filename)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loading = false
          })
      }
    },
    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },

    getProductsPermit() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.productsPermit.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.productsPermit.push(element.slug)
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getAfiliados() {
      this.axios
        .post(
          'afiliados/get-clients-corporate',
          { afiliatesCorporatives: true },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.afiliates = res.data.data.clients
        })
    },

    getProveedoresHotel() {
      this.axios
        .get('afiliados/byproduct/hotels?only_contrate=1', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.proveedoresHotel.push({
            name_comercial: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.proveedoresHotel.push(element)
          })
        })
    },
    getOperadoresHotel() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          this.operadoresHotel.push({
            name: 'Todos',
            id: 0,
          })
          result.forEach(element => {
            this.operadoresHotel.push(element)
          })
        })
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotels = res.data.data
          }
        })
    },

    clearSearch() {
      this.model = {
        ...this.model,
        proveedor_id: 0,
        destino: 'Todos',
        dateIn: null,
        dateOut: null,
        operador_id: 0,
        hotels: [],
      }
      this.updateItemsTarifarios([])
    },

    send() {
      this.isSidebarSendActive = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
